<template>
  <div v-if="msgManageData">
    <div
      style="
        display: flex;
        align-items: stretch;
        height: 120px;
        justify-content: space-between;
        margin: 20px 0;
      "
    >
      <Card class="cardsty">
        <h2>动员群</h2>
        <br />
        <span>{{ msgManageData.groupName }}</span>
      </Card>
      <Card class="cardsty">
        <h2>群人数</h2>
        <br />
        <span>共 {{ msgManageData.contacts }} 人</span>
      </Card>
      <Card class="cardsty">
        <h2>管理员</h2>
        <br />
        <span>{{ msgManageData.manager }}</span>
      </Card>

      <Card class="cardsty">
        <h2>发言记录</h2>
        <br />
        <span>共 {{ msgManageData.messageCount }} 条</span>
      </Card>
    </div>
    <div style="margin: 20px 0; text-align: left">
      发言类型：
      <Select v-model="model1" style="width: 200px" @on-change="speakType">
        <Option
          v-for="item in cityList"
          :value="item.value"
          :key="item.value"
          >{{ item.label }}</Option
        >
      </Select>
      <Checkbox
        label="1"
        border
        true-value="1"
        false-value="0"
        v-model="searchData.atStatus"
        style="margin: 0 10px"
        >@我</Checkbox
      >

      <DatePicker
        v-model="searchTime"
        type="daterange"
        placement="bottom-end"
        placeholder="开始时间和结束时间"
        style="width: 200px; margin-right: 10px"
      ></DatePicker>
      <Button
        type="primary"
        style="margin-right: 10px"
        @click="getList"
        icon="ios-search"
        >查询</Button
      >
      <Button
        type="warning"
        icon="ios-download-outline"
        style="margin-right: 10px"
        @click="exportExcel"
        >导出全部</Button
      >
      <Button type="success" @click="exportWeek" icon="ios-download-outline"
        >导出本周</Button
      >
    </div>
    <div v-if="chatDataList.length > 0">
      <Card
        v-for="item in chatDataList"
        :key="item.id"
        style="margin-bottom: 14px"
      >
        <p slot="title">
          <span>{{ item.houseNum }}房-</span>
          <span>{{ item.nickname }}</span>
          <span style="margin: 0 30px">{{ item.gmtCreate }}</span>
          <span>
            <Checkbox
              :label="item.id"
              v-model="item.status"
              @on-change="changeStatus(item)"
              style="margin: 0 10px"
              >显示</Checkbox
            >
          </span>
          <span style="margin: 0 30px">{{ item.groupName }}</span>
        </p>
        <div class="content" style="paddind: 20px">
          <video :src="item.msg" v-if="item.msgType == '4'"></video>
          <audio :src="item.msg" v-else-if="item.msgType == '3'"></audio>
          <img
            :src="item.msg"
            style="width: 200px; max-height: 300px"
            v-else-if="item.msgType == '2'"
            alt=""
          />
          <article style="text-indent: 2em; text-align: start" v-else>
            {{ item.msg }}
          </article>
        </div>
        <p style="text-align: right; margin-top: 10px">
          <Input
            :maxlength="400"
            v-model.trim="item.message"
            show-word-limit
            type="textarea"
            :rows="1"
          />
          <Button
            type="primary"
            style="margin-top: 10px"
            @click="replyMessage(item)"
            >回复</Button
          >
        </p>
        <div
          v-if="item.chatRecordReplyVos && item.chatRecordReplyVos.length > 0"
          style="margin-top: 12px"
        >
          <List border item-layout="vertical">
            <div slot="header">
              <span>我回复的</span>
            </div>
            <ListItem
              v-for="(subItem, subIndex) in item.chatRecordReplyVos"
              :key="subIndex"
            >
              <div>{{ subItem.gmtCreate | filterTime(that) }}</div>
              <p style="text-indent: 2em">
                {{ subItem.content }}
              </p>
            </ListItem>
          </List>
        </div>
      </Card>
      <Page
        show-total
        @on-change="currentPage"
        @on-page-size-change="hadlePageSize"
        :total="total"
        size="small"
        show-sizer
        :page-size.sync="pageSize"
        :curret.sync="pageNum"
        show-elevator
      ></Page>
    </div>
    <Card v-else dis-hover>
      <div style="text-align: center; line-height: 100px; font-size: 24px">
        无发言记录
      </div>
    </Card>
  </div>
</template>

<script>
import { format } from "@/utils/formatTime.js";
import qs from "qs";
export default {
  props: ["msgManageData"],
  data() {
    return {
      that: this,
      checked: true,
      disabled: false,
      cityList: [],
      model1: "",
      aiteme: "",
      chatDataList: [],
      showcheck: false,
      recordVal: "",
      helpDefaultTime: "",
      searchTime: [],
      border: ["香蕉"],
      searchData: {
        endTime: "",
        startTime: "",
        chatUserId: "",
        groupId: "",
      },
      total: 0,
      pageNum: 1,
      pageSize: 10,
    };
  },
  mounted() {
    this.getList();
  },
  filters: {
    filterTime(val, that) {
      if (!val) return "";
      return that.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss");
    },
  },
  methods: {
    // 回复
    replyMessage(item) {
      if (
        item.message == "" ||
        item.message == undefined ||
        item.message == null
      ) {
        this.$Message.error({
          background: true,
          content: "请输入回复内容",
        });
        return;
      }
      this.$post("/sendmsg/pc/chat/record/replyMessage", {
        chatRecordId: item.id,
        content: item.message,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        groupId: item.groupId,
        msgType: '1',
        oemCode: parent.vue.oemInfo.oemCode,
        replier: parent.vue.loginInfo.userinfo.realName,
         repliedGlobalId: item.chatUserId
      }).then((res) => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: "回复成功",
          });
          this.msgManageData.messageCount += 1;
          this.getList();
          this.$emit("replyMessageSuccess");
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    currentPage(page) {
      this.pageNum = page;
      this.getList();
    },
    hadlePageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    //发言类型
    speakType(current) {
      console.log(current);
    },

    //查询按钮/首次页面获取数据
    getList() {
      if (this.searchTime && this.searchTime[0]) {
        this.searchData.startTime = new Date(this.searchTime[0]).getTime();
        this.searchData.endTime = new Date(this.searchTime[1]).getTime() + 60 * 60 * 24;
      } else {
        this.searchData.startTime = "";
        this.searchData.endTime = "";
      }
      this.searchData.groupId = this.msgManageData.groupId;
      let params = {
        ...this.searchData,
        page: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$get("/sendmsg/pc/chat/record/selectRecordPage", params)
        .then((res) => {
          if (res.code === "200") {
            res.dataList.forEach((item) => {
              item.gmtCreate = format(item.gmtCreate, "yyyy-MM-dd");
              item.status = item.status === 1 ? false : true;
            });
            this.chatDataList = res.dataList;
            this.total = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: "数据获取失败",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeStatus(data) {
      if (data.id) {
        let params = {
          recordId: data.id,
          status: data.status ? 0 : 1,
        };
        this.$post("/sendmsg/pc/chat/record/changeRecordStatus", params)
          .then((res) => {
            if (res.code === "200") {
              this.$Message.success("操作成功");
            } else {
              this.$Message.error("操作失败");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //导出
    exportExcel() {
      this.$Message.loading({
        content: "正在导出数据，请稍等...",
        duration: 0,
      });
      let oneDayLong = 24 * 60 * 60 * 1000;
      let now = new Date();
      let sundayTime = now.getTime() + (7 - now.getDay()) * oneDayLong;
      let sunday = new Date(sundayTime);

      this.$.ajax({
        url:
          window.vue.getProxy()["/sendmsg"].target +
          "/upload/chatRecord/export",
        type: "POST",
        xhrFields: { responseType: "blob" },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          openId:window.sessionStorage.getItem("openId"),
          Authorization:window.sessionStorage.getItem('accessToken')
        },
        data: {
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          endTime: new Date(
            this.$core.formatDate(sunday, "yyyy-MM-dd")
          ).getTime(),
          startTime: 0,
          groupId: this.msgManageData.groupId,
        },
        success: (res) => {
          if (res) {
            const blob = res;
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download =
              this.msgManageData.groupName + "本周发言记录.xlsx";
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
            this.$Message.destroy();
          } else {
            this.$Message.error({
              background: true,
              content: "暂未查询到发言记录",
            });
          }
        },
      });
    },

    exportWeek() {
      this.$Message.loading({
        content: "正在导出数据，请稍等...",
        duration: 0,
      });
      let oneDayLong = 24 * 60 * 60 * 1000;
      let now = new Date();
      let mondayTime = now.getTime() - (now.getDay() - 1) * oneDayLong;
      let sundayTime = now.getTime() + (7 - now.getDay()) * oneDayLong;
      let monday = new Date(mondayTime);
      let sunday = new Date(sundayTime);

      this.$.ajax({
        url:
          window.vue.getProxy()["/sendmsg"].target +
          "/upload/chatRecord/export",
        type: "POST",
        xhrFields: { responseType: "blob" },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          openId:window.sessionStorage.getItem("openId"),
          Authorization:window.sessionStorage.getItem('accessToken')
        },
        data: {
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          endTime: new Date(
            this.$core.formatDate(sunday, "yyyy-MM-dd")
          ).getTime(),
          startTime: new Date(
            this.$core.formatDate(monday, "yyyy-MM-dd")
          ).getTime(),
          groupId: this.msgManageData.groupId,
        },
        success: (res) => {
          if (res) {
            const blob = res;
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download =
              this.msgManageData.groupName + "本周发言记录.xlsx";
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
            this.$Message.destroy();
          } else {
            this.$Message.error({
              background: true,
              content: "暂未查询到发言记录",
            });
          }
        },
      });
    },
  },
};
</script>
    
<style scoped lang='less'>
.ivu-card-head {
  p {
    display: inline-block;
    width: 100%;
    height: 26px;
    line-height: 26px;
    font-size: 18px;
    color: #17233d;
    font-weight: 500;
    text-align: left;
  }
}

.ivu-page {
  text-align: right;
}
.cardsty {
  min-width: 250px;
  width: 23%;
  margin-right: 2%;
  background: #2db7f5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /deep/.ivu-card-body {
    width: 100%;
  }
  &:last-of-type {
    margin-right: 0;
  }
  h2 {
    font-size: 18px;
  }
  span {
    width: 100%;
    font-size: 20px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>